export default function Footer() {
    return (
        <footer className="flex flex-col items-center justify-between px-4 py-12 mx-auto max-w-7xl md:flex-row">
            <p className="mb-8 text-sm text-center text-gray-700 md:text-left md:mb-0">
                © Copyright 2024 GJstyle. All Rights Reserved.
            </p>

            <p className="uppercase underline text-sm text-center md:ml-5 lg:ml-5">
                Under no circumstances should your credentials be shared with anyone
            </p>
        </footer>
    )
}
